import { AssessmentStatusEnum } from "@/core/enums/assessments";
import { FilterItemModel } from "@/core/models/filter-item";
import { COLORS } from "@/core/constants/colors";

export const AssessmentStatuses = [
  AssessmentStatusEnum.DRAFT,
  AssessmentStatusEnum.COMPLETED,
  AssessmentStatusEnum.ON_EVALUATOR,
  AssessmentStatusEnum.ON_SUPPLIER,
];

export const ASSESSMENT_STATUS_VIEW_SUPPLIER = {
  [AssessmentStatusEnum.COMPLETED]: {
    value: "Completed",
    color: COLORS.GRAY,
  },
  [AssessmentStatusEnum.ON_EVALUATOR]: {
    value: "Submitted",
    color: COLORS.BLUE_HOVER,
  },
  [AssessmentStatusEnum.ON_SUPPLIER]: {
    value: "In progress",
    color: COLORS.YELLOW,
  },
  [AssessmentStatusEnum.OVERDUE]: {
    value: "Overdue",
    color: COLORS.GRAY_08,
  },
  [AssessmentStatusEnum.DRAFT]: {
    value: "Draft",
    color: COLORS.BLUE,
  },
};

export const ASSESSMENT_STATUS_VIEW_CLIENT = {
  [AssessmentStatusEnum.COMPLETED]: {
    value: "Done",
    color: COLORS.GRAY,
  },
  [AssessmentStatusEnum.ON_EVALUATOR]: {
    value: "In evaluation",
    color: COLORS.BLUE_HOVER,
  },
  [AssessmentStatusEnum.ON_SUPPLIER]: {
    value: "In progress",
    color: COLORS.YELLOW,
  },
  [AssessmentStatusEnum.OVERDUE]: {
    value: "Late",
    color: COLORS.GRAY_08,
  },
  [AssessmentStatusEnum.DRAFT]: {
    value: "Draft",
    color: COLORS.BLUE,
  },
};

export const ASSESSMENT_STATUS_VIEW_EVALUATOR = {
  [AssessmentStatusEnum.COMPLETED]: {
    value: "Done",
    color: COLORS.GRAY,
  },
  [AssessmentStatusEnum.ON_EVALUATOR]: {
    value: "To evaluate",
    color: COLORS.BLUE_HOVER,
  },
  [AssessmentStatusEnum.ON_SUPPLIER]: {
    value: "In progress",
    color: COLORS.YELLOW,
  },
  [AssessmentStatusEnum.OVERDUE]: {
    value: "Late",
    color: COLORS.GRAY_08,
  },
  [AssessmentStatusEnum.DRAFT]: {
    value: "Draft",
    color: COLORS.BLUE,
  },
};

export const AssessmentStatusesFilters: Array<FilterItemModel> =
  AssessmentStatuses.map(
    (val) => new FilterItemModel(val, ASSESSMENT_STATUS_VIEW_CLIENT[val].value)
  );

export const AssessmentStatusesFiltersForSupplier: Array<FilterItemModel> =
  AssessmentStatuses.filter((item) => item !== AssessmentStatusEnum.DRAFT).map(
    (val) =>
      new FilterItemModel(val, ASSESSMENT_STATUS_VIEW_SUPPLIER[val].value)
  );

export const AssessmentStatusesFiltersForEvaluator: Array<FilterItemModel> =
  AssessmentStatuses.filter(
    (status) => status !== AssessmentStatusEnum.DRAFT
  ).map(
    (val) =>
      new FilterItemModel(val, ASSESSMENT_STATUS_VIEW_EVALUATOR[val].value)
  );
