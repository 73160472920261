import { FiltersModel } from "@/core/models/filters";

export enum AssessmentFilterItem {
  NAME = "name",
  STATUS = "status",
  CATEGORY = "category",
  SUPPLIER = "supplier",
  OVERDUE = "is_overdue",
}

export class AssessmentFiltersModel extends FiltersModel {
  public [AssessmentFilterItem.STATUS]: string;
  public [AssessmentFilterItem.CATEGORY]: string | number;
  public [AssessmentFilterItem.OVERDUE]: boolean;

  constructor(query: any) {
    super();
    (this[AssessmentFilterItem.CATEGORY] =
      query[AssessmentFilterItem.CATEGORY]),
      (this[AssessmentFilterItem.STATUS] = query[AssessmentFilterItem.STATUS]),
      (this[AssessmentFilterItem.OVERDUE] = query[AssessmentFilterItem.OVERDUE]),
      this.initAttrsFromQuery(query);
  }
}
